import 'plyr/dist/plyr.css'
import '../styles/main.css'
import 'focus-visible'
import { listen } from 'quicklink'
import { add, on, remove, size, toggle, qs, qsa, each, attr } from 'martha'
import app from './app'
import loadFonts from './lib/fonts'
import delegate from './lib/delegate'
import scrollTo from './lib/scrollTo'
import isUrlExternal from './lib/isUrlExternal'
import clipboardCopy from './lib/clipboardCopy'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import ScrambleTextPlugin from 'gsap/ScrambleTextPlugin'
import VimeoPlayer from '@vimeo/player'
import './lib/d3'

window.Vimeo = { Player: VimeoPlayer }

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual'
}

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrambleTextPlugin)

if (process.env.NODE_ENV === 'production') {
  listen()
}

on(window, 'resize', resize)
setupGridOverlayToggle()
handleMailtoAndTelLinks()
handleAnchorLinks()
openExternalLinksInNewTab()
wrapTables()

loadFonts([
  { family: 'ID Display 0' },
  { family: 'ID Display 25' },
  { family: 'ID Display 50' },
  { family: 'ID Display 75' },
  { family: 'ID Display 100' },
  { family: 'Gerstner Programm', options: { weight: 400 } },
  { family: 'Gerstner Programm', options: { weight: 300 } },
  { family: 'Gerstner Programm', options: { weight: 300, style: 'italic' } },
  { family: 'Bradford', options: { weight: 300 } },
  { family: 'Bradford', options: { weight: 300, style: 'italic' } },
]).then(() => {
  remove(document.body, 'opacity-0')
  app.mount()
  resize()
  scrollTo({
    href: window.location.href,
    duration: 0,
    pushState: false,
  })
})

function resize() {
  const cachedScrollY = window.scrollY
  window.scroll(0, 0)
  app.emit('resize', size())
  window.scroll(0, cachedScrollY)
}

function handleMailtoAndTelLinks() {
  let div = null
  let timeoutId = null
  delegate(
    document,
    'a[href^="mailto:"], a[href^="tel:"]',
    'click',
    function (link, ev) {
      const [type, data] = link.getAttribute('href').split(':')

      if (type === 'tel' && app.getState().isMobile) return

      ev.preventDefault()

      clipboardCopy(data).then(() => {
        if (timeoutId) {
          clearTimeout(timeoutId)
          timeoutId = null
        }

        if (div) {
          div.remove()
          div = null
        }

        div = document.createElement('div')
        add(
          div,
          'font-normal text-12 leading-100 tracking-6 uppercase fixed top-0 left-0 text-white mix-blend-difference',
        )
        div.textContent = `${
          type === 'mailto' ? 'Email' : 'Phone Number'
        } Copied`
        div.style.zIndex = 9999

        gsap.set(div, {
          x: ev.clientX + 20,
          y: ev.clientY + 20,
        })

        document.body.append(div)

        let offMove = on(document, 'mousemove', ({ clientX, clientY }) => {
          gsap.set(div, {
            x: clientX + 20,
            y: clientY + 20,
          })
        })

        timeoutId = setTimeout(() => {
          timeoutId = null
          div.remove()
          div = null
          offMove()
        }, 3000)
      })
    },
  )
}

function handleAnchorLinks() {
  delegate(document, 'a[href*="#"]', 'click', (el, ev) => {
    const href = el.getAttribute('href')

    if (isUrlExternal(href, window.location.pathname)) return

    const url = new URL(href, window.location.origin)
    const currentPath = normalizePath(window.location.pathname)
    const targetPath = normalizePath(url.pathname)

    if (currentPath === targetPath) {
      ev.preventDefault()
      scrollTo({ href: el.getAttribute('href') })
    }

    function normalizePath(pathname) {
      return `/${pathname.split('/').filter(Boolean).join('/')}`
    }
  })
}

function openExternalLinksInNewTab() {
  const links = qsa('a[href]:not([target="_blank"]')
  each(links, link => {
    const href = attr(link, 'href')
    if (href) {
      const isExternal = isUrlExternal(href, window.location.host)
      if (isExternal || href.slice(-4) === '.pdf') {
        attr(link, 'target', '_blank')
        attr(link, 'rel', 'noopener noreferrer')
      }
    }
  })
}

function wrapTables() {
  const tables = qsa('.rich-text table')
  each(tables, table => {
    const clonedTable = table.cloneNode(true)
    const wrapper = document.createElement('section')
    add(wrapper, 'bleed s:no-bleed px-15 s:px-0 overflow-x-auto no-scrollbar')
    wrapper.append(clonedTable)
    table.insertAdjacentElement('afterend', wrapper)
    table.remove()
  })
}

function setupGridOverlayToggle() {
  const grid = qs('.js-grid')

  on(
    window,
    'keyup',
    ({ key, ctrlKey }) => key === 'g' && ctrlKey && toggle(grid, 'opacity-0'),
  )
}
