
import * as d3 from 'd3';

const ends = (element) => {
    return window.location.href.endsWith(element);
}
const eoys_2023_animations = ['/end-of-year-show/eoys-2023/time/', '/end-of-year-show/eoys-2023/creation/', '/end-of-year-show/eoys-2023/causation/', '/end-of-year-show/eoys-2023/unity/', '/end-of-year-show/eoys-2023/structure/']
const eoys_2024_animations_project_pages = ['/end-of-year-show/eoys-2024/eoys-2024_designers/', '/end-of-year-show/eoys-2024/eoys-2024-team/'];
const eoys_2024_animations = ['/end-of-year-show/eoys-2024/', '/end-of-year-show/eoys-2024/endless-possibility/', '/end-of-year-show/eoys-2024/driving-solutions/', '/end-of-year-show/eoys-2024/coming-into-view/', '/end-of-year-show/eoys-2024/renewing-ideas/'];  

document.addEventListener("DOMContentLoaded", async (event) => {      
    if ( eoys_2024_animations_project_pages.some(ends) || window.location.href.includes('projects') ) {
        
        if([...document.querySelector('nav ul').children][0].innerText.trim() == "EOYS 2024" ) {    
            // Vector operations for managing positions and interactions
            const createVector = (x, y) => ({ x, y });
            const vectorSub = (v1, v2) => ({ x: v1.x - v2.x, y: v1.y - v2.y });
            const vectorMag = (v) => Math.sqrt(v.x * v.x + v.y * v.y);
            const vectorMult = (v, scalar) => ({ x: v.x * scalar, y: v.y * scalar });
            const vectorAdd = (v1, v2) => ({ x: v1.x + v2.x, y: v1.y + v2.y });
            
            function addInk(x, y, radius, color) {
                let drop = createDrop(x, y, radius, color);
                drops.forEach((other) => marble(other, drop));
                drops.push(drop);
            }
            
            function createDrop(x, y, radius, color) {
                const circleDetail = 300;
                const vertices = Array.from({ length: circleDetail }, (_, i) => {
                    const angle = (i / circleDetail) * 2 * Math.PI;
                    return vectorAdd(
                        createVector(x, y),
                        vectorMult(createVector(Math.cos(angle), Math.sin(angle)), radius)
                    );
                });
                return { center: createVector(x, y), radius, color, vertices };
            }
            
            function marble(drop, other) {
                drop.vertices = drop.vertices.map((vertex) => {
                    const p = vectorSub(vertex, other.center);
                    const m = vectorMag(p);
                    const root = Math.sqrt(1 + (other.radius * other.radius) / (m * m));
                    return vectorAdd(other.center, vectorMult(p, root));
                });
            }
            
            
            const container = d3.select('#root').insert('div','#maincontent').classed('flex',true).attr('display','block').style('height','150px').attr('width','100%').node()
            
            let width = container.offsetWidth;
            let height = container.offsetHeight;
            let drawText = true;
            let drops = [];
            let counter = 0;
            let currentColor;
            let colorPalette = ["#FD5527", "#F3383D", "#5494A4","#F1C22A","#2376A4"];
            let options = {"aspect":1,"Minimum Blob Radius":10,"Maximum Blob Radius":180,"Blob Count":12,"Mouse Blob Radius":width/60}
            let backgroundColors = ["#E5EFF1",'#FFE6DD','#FDF5DC','#FCE1E0'];
            let backgroundColor = backgroundColors[Math.floor(Math.random()*backgroundColors.length)]
            
            let mousePaint = true;
            
            
            var canvas = d3.select(container)
            .append('canvas')
            .attr('width', width)
            .attr('height', height);
            
            var context = canvas.node().getContext('2d');
            
            context.fillStyle = backgroundColor;
            context.fillRect(0, 0, width, height);
            
            d3.range(options["Blob Count"]).forEach((d) => {
                addInk(
                    Math.random() * width,
                    Math.random() * height,
                    options["Minimum Blob Radius"] +
                    Math.random() * options["Maximum Blob Radius"],
                    colorPalette[Math.floor(Math.random() * colorPalette.length)]
                );
            });
            
            
            if (mousePaint) {
                const dragBehavior = d3.drag()
                .on("start", dragStarted)
                .on("drag", dragging)
                .on("end", dragEnded);
                
                d3.select(context.canvas)
                .call(dragBehavior)
                
                function dragStarted(event) {
                    drawText = false;
                    // Prevent default behavior to handle touch events correctly
                    if (event.sourceEvent) event.sourceEvent.preventDefault();
                    currentColor = colorPalette[Math.floor(Math.random() * colorPalette.length)];
                }
                
                function dragging(event) {
                    let coords;
                    if (event.identifier == "mouse") {
                        coords = d3.pointer(event, context.canvas);
                    } else {
                        coords = d3.pointer(event.sourceEvent.touches[0], context.canvas); //d3.pointer(event, context.canvas);
                    }
                    addInk(
                        coords[0],
                        coords[1],
                        options["Mouse Blob Radius"],
                        currentColor
                    );
                }
                
                function dragEnded(event) {
                    // Optionally, handle the end of dragging
                } 
            }
            
            requestAnimationFrame(animate);
            
            function windowResize() {
                width = container.offsetWidth
                canvas.attr('width', width)
                animate();
            };
            
            
            window.addEventListener('resize', windowResize);
            
            
            function animate(){
                context.fillStyle = backgroundColor;
                context.fillRect(0, 0, width, height);
                
                for (let drop of drops) {
                    context.fillStyle = drop.color;
                    context.beginPath();
                    context.moveTo(drop.vertices[0].x, drop.vertices[0].y);
                    for (let vertex of drop.vertices) {
                        context.lineTo(vertex.x, vertex.y);
                    }
                    
                    context.closePath();
                    context.fill();
                }
                
                
                requestAnimationFrame(animate);
            }
        }
    }

    if ( eoys_2024_animations.some(ends) ) {
        // Vector operations for managing positions and interactions
        const createVector = (x, y) => ({ x, y });
        const vectorSub = (v1, v2) => ({ x: v1.x - v2.x, y: v1.y - v2.y });
        const vectorMag = (v) => Math.sqrt(v.x * v.x + v.y * v.y);
        const vectorMult = (v, scalar) => ({ x: v.x * scalar, y: v.y * scalar });
        const vectorAdd = (v1, v2) => ({ x: v1.x + v2.x, y: v1.y + v2.y });
        
        function addInk(x, y, radius, color) {
            let drop = createDrop(x, y, radius, color);
            drops.forEach((other) => marble(other, drop));
            drops.push(drop);
        }
        
        function createDrop(x, y, radius, color) {
            const circleDetail = 300;
            const vertices = Array.from({ length: circleDetail }, (_, i) => {
                const angle = (i / circleDetail) * 2 * Math.PI;
                return vectorAdd(
                    createVector(x, y),
                    vectorMult(createVector(Math.cos(angle), Math.sin(angle)), radius)
                );
            });
            return { center: createVector(x, y), radius, color, vertices };
        }
        
        function marble(drop, other) {
            drop.vertices = drop.vertices.map((vertex) => {
                const p = vectorSub(vertex, other.center);
                const m = vectorMag(p);
                const root = Math.sqrt(1 + (other.radius * other.radius) / (m * m));
                return vectorAdd(other.center, vectorMult(p, root));
            });
        }
        
        const container = d3.select('div.absolute img').node().parentNode
        const image = d3.select('div.absolute img').node().remove()
        let width = container.offsetWidth;
        let height = container.offsetHeight;
        let drawText = true;
        let drops = [];
        let counter = 0;
        let currentColor;
        let colorPalette; 
        let options = {"aspect":1,"Minimum Blob Radius":10,"Maximum Blob Radius":250,"Blob Count":10,"Mouse Blob Radius":width/20}
        let backgroundColors = {converge: "#E5EFF1", diverge:'#FFE6DD',emerge:'#FDF5DC',resurge:'#FCE1E0'}
        let mousePaint = true;
        let backgroundColor;
        
        
        if(window.location.href.split('/').length == 7){
            colorPalette = ["#FD5527", "#F3383D", "#5494A4","#F1C22A","#2376A4"];
            backgroundColor = backgroundColors[document.querySelector('div h2 em').innerText.toLowerCase()]
        }
        else{
            colorPalette = ["#F59088", "#FDE4DE", "#B3C6CD","#6FA1AF","#F16F4A"];
            backgroundColor = "#FEEEEC"
        }
        
        
        
        var canvas = d3.select(container)
        .append('canvas')
        .attr('width', width)
        .attr('height', height);
        
        var context = canvas.node().getContext('2d');
        
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, width, height);
        
        d3.range(options["Blob Count"]).forEach((d) => {
            addInk(
                Math.random() * width,
                Math.random() * height,
                options["Minimum Blob Radius"] +
                Math.random() * options["Maximum Blob Radius"],
                colorPalette[Math.floor(Math.random() * colorPalette.length)]
            );
        });
        
        
        if (mousePaint) {
            const dragBehavior = d3.drag()
            .on("start", dragStarted)
            .on("drag", dragging)
            .on("end", dragEnded);
            
            d3.select(context.canvas)
            .call(dragBehavior)
            
            function dragStarted(event) {
                drawText = false;
                // Prevent default behavior to handle touch events correctly
                if (event.sourceEvent) event.sourceEvent.preventDefault();
                currentColor = colorPalette[Math.floor(Math.random() * colorPalette.length)];
            }
            
            function dragging(event) {
                let coords;
                if (event.identifier == "mouse") {
                    coords = d3.pointer(event, context.canvas);
                } else {
                    coords = d3.pointer(event.sourceEvent.touches[0], context.canvas); //d3.pointer(event, context.canvas);
                }
                addInk(
                    coords[0],
                    coords[1],
                    options["Mouse Blob Radius"],
                    currentColor
                );
            }
            
            function dragEnded(event) {
            } 
        }
        
        requestAnimationFrame(animate);
        
        let text;
        
        if(window.location.href.split('/').length == 7){
            text = "Click and drag to " + document.querySelector('div h2 em').innerText.toLowerCase()
        }
        else{
            //text = "Click and drag to " + ["diverge",'converge','resurge','emerge'][Math.floor(Math.random()*4)]
            text = "Click and drag to celebrate with us"
        }
        
        
        function animate(){
            context.fillStyle = backgroundColor;
            context.fillRect(0, 0, width, height);
            
            for (let drop of drops) {
                context.fillStyle = drop.color;
                context.beginPath();
                context.moveTo(drop.vertices[0].x, drop.vertices[0].y);
                for (let vertex of drop.vertices) {
                    context.lineTo(vertex.x, vertex.y);
                }
                
                context.closePath();
                context.fill();
            }
            
            if(drawText){
                context.textBaseline = "middle";
                context.textAlign = "center";
                context.font = width/16 + "px Gerstner Programm,Helvetica Neue,Arial,sans-serif";
                context.fillStyle = "#000000";
                context.fillText(text, width/2,height/2);
            }
            requestAnimationFrame(animate);
        }
    }
    if (eoys_2023_animations.some(ends)) {
            const container = d3.select([...document.querySelectorAll('img.absolute')][0]).node().parentNode.parentNode
            const image = d3.select([...document.querySelectorAll('img.absolute')][0]).node().parentNode.remove()
            const width = container.offsetWidth;
            const height = container.offsetHeight;
            const svg = d3.select(container).append('svg').attr("width", width).attr("height", height);
            
            const uO = {"xCount":13,
            "yCount":20,
            "particleColor":"#e66a2c",
            "minRadiusMultiplier":0.5,
            "maxRadiusMultiplier":2,
            "minOpacity":0.25,
            "maxOpacity":1,
            "lozengeOffset":1,
            "speed":0.01,
            "frequency":1,
            "animate":false};
        
            const sO = {
            "particleColor": "#0500f5",
            "strokeWidth": .5,
            "minRadius": 0,
            "maxRadius": 20,
            "minOpacity": 0.25,
            "maxOpacity": 1,
            "minDuration": 1500,
            "maxDuration": 5000,
            "fadeOutDuration": 65,
            "minDistance": 50,
            "maxDistance": width/2 - 20,
            "spawnChance": 0.75,
            "animate": true
            }
        
            const cO = {
            "particleColor":"#d632da",
            "spacing":100,
            "margin":100,
            "clusterRadius":10,
            "clusterSpreadRate":10,
            "minOpacity":0.25,
            "maxOpacity":0.8,
            "minRadius":5,
            "maxRadius":50,
            "dropDelay":800,
            "minClusterCount":2,
            "maxClusterCount":4,
            "fallDuration":10000,
        
            }
        
            const tO= {
            "particleColor":"#050517",
            "margin":50,
            "yMovement":15,
            "stripes":20,
            "steps":7,
            "minRadius":5,
            "maxRadius":15,
            "maxOpacity":0.75,
            "minOpacity":0.2,
            "pathStrokeWidth":15,
            "pathOpacity":0.3,
            "phaseRandomness":2000,
            "enterDuration":500,
            "stepDuration":500,
            "exitDuration":250,
            "spawnDelay":1200,
            "animate":false
            }
        
            const zO = ({
            particleColor: "#7bea49",
            squareSize: 75,
            lineCount: 4,
            squaresPerLine: 20,
            maxOpacity: 0.75,
            minOpacity: 0.2,
            enterDuration: 250,
            stepDuration: 6000,
            exitDuration: 250,
            spawnDelay: 2400,
            animate: true
            })
        
            
            const r = (min, max) => {
            return Math.random() * (max - min) + min;
            }
        
            if((window.location.href).split('/')[5] == "structure"){
        
        
            const alongLine = (x1, y1, x2, y2, d) => {
                const xlen = x2 - x1;
                const ylen = y2 - y1;
                const hlen = Math.sqrt(Math.pow(xlen, 2) + Math.pow(ylen, 2));
                const smallerLen = hlen - d;
                const ratio = smallerLen / hlen;
        
                const smallerXLen = xlen * ratio;
                const smallerYLen = ylen * ratio;
                const smallerX = x1 + smallerXLen;
                const smallerY = y1 + smallerYLen;
        
                return [smallerX, smallerY];
            }
        
            const sleep = async ms => {return new Promise(resolve => setTimeout(resolve, ms))};
        
            const g = svg
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
        
            const pt = {
                startOpacity: 0,
                startRadius: 0,
                source: [0, 0]
            };
        
            let theta = 0;
        
            animate();
            const intervalId = window.setInterval(animate, 100);
        
            function animate(){
                if (Math.random() < sO.spawnChance){
                theta = Math.random() * 2 * 3.1415;
        
                pt.endOpacity = r(sO.minOpacity, sO.maxOpacity);
                pt.endRadius = r(sO.minRadius, sO.maxRadius);
                pt.duration = r(sO.minDuration, sO.maxDuration);
                pt.target = [
                Math.cos(theta) * r(sO.minDistance, sO.maxDistance),
                Math.sin(theta) * r(sO.minDistance, sO.maxDistance)
                ];
        
                pt.along = alongLine(0, 0, pt.target[0], pt.target[1], pt.endRadius);
        
                g.append("circle")
                .attr("r", pt.startRadius)
                .attr("opacity", pt.startOpacity)
                .attr("fill", sO.particleColor)
                .attr("cx", pt.source[0])
                .attr("cy", pt.source[1])
                .transition()
                .duration(pt.duration)
                .ease(d3.easeCubicOut)
                .attr("r", pt.endRadius)
                .attr("opacity", pt.endOpacity)
                .attr("cx", pt.target[0])
                .attr("cy", pt.target[1])
                .transition()
                .duration(sO.fadeOutDuration)
                .attr("opacity", 0)
                .remove();
        
                g.append("line")
                .attr("x1", pt.source[0])
                .attr("y1", pt.source[1])
                .attr("opacity", pt.startOpacity)
                .attr("stroke", sO.particleColor)
                .attr("stroke-width", sO.strokeWidth)
                .transition()
                .duration(pt.duration)
                .ease(d3.easeCubicOut)
                .attr("x2", pt.along[0])
                .attr("y2", pt.along[1])
                .attr("opacity", pt.endOpacity)
                .transition()
                .duration(sO.fadeOutDuration)
                .attr("opacity", 0)
                .remove();       
            }
            }
        }
        
        if((window.location.href).split('/')[5] == "unity"){
        
            const aspectRatio = uO.yCount / uO.xCount;
            const cellSize = width / uO.xCount;
        
            const g = svg
            .append("g")
            .attr('transform-origin','center')
            .attr("transform", "scale(.9 .9) translate(" + width / 2 + "," + height / 2 + ")");
        
            const rings = uO.xCount < uO.yCount ? uO.xCount / 2 : uO.yCount / 2;
        
            const centers = d3
            .range(uO.yCount)
            .map((y) =>
            d3.range(uO.xCount).map((x) => {
                const rX = x < uO.xCount / 2 ? x : uO.xCount - x - 1;
                const rY = y < uO.yCount / 2 ? y : uO.yCount - y - 1;
                const oY =
                y >= uO.yCount / 2
                ? y - uO.lozengeOffset
                : uO.yCount - y - (uO.lozengeOffset + 1);
        
                const ring = rX < rY ? rX : rY;
        
                return {
                x: x - uO.xCount / 2,
                y: y - uO.yCount / 2,
                r: 0,
                ring,
                opacity: rX < oY ? uO.minOpacity : uO.maxOpacity
                };
            })
            )
            .flat();
        
            const circles = g
            .selectAll()
            .data(centers)
            .enter()
            .append("circle")
            .attr("r", (d) => d.r)
            .attr("opacity", (d) => d.opacity)
            .attr("fill", uO.particleColor)
            .attr("cx", (d) => d.x * cellSize + cellSize / 2)
            .attr("cy", (d) => d.y * cellSize + cellSize / 2);
        
            let i = 0;
            let ringSizes;
        
            animate();
            const intervalId = window.setInterval(animate, 10);
            function animate(){
        
            ringSizes = d3
            .range(rings)
            .map(
                (r) =>
                ((Math.sin((r / rings) * (uO.frequency * 6.28) + i) + 1) / 2) *
                ((cellSize / 2) * uO.maxRadiusMultiplier -
                (uO.minRadiusMultiplier * cellSize) / 2) +
                uO.minRadiusMultiplier * (cellSize / 2)
                );
        
            circles.attr("r", (d) => ringSizes[d.ring]);
            i = i + uO.speed;
            }
        }
        
        if((window.location.href).split('/')[5] == "time"){
            const g = svg
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + 0 + ")");
        
            const stepSize = (width - tO.margin * 2) / 2 / tO.steps;
        
            const stripeSpacing = (height - tO.margin * 2) / tO.stripes;
        
            const moves = d3
            .range(tO.steps + 1)
            .map((d) => [d * stepSize, d % 2 == 0 ? -tO.yMovement : tO.yMovement]);
        
            const sizeScale = d3
            .scaleLinear()
            .domain([0, tO.steps + 1])
            .range([tO.maxRadius, tO.minRadius]);
        
            const opacityScale = d3
            .scaleLinear()
            .domain([0, tO.steps + 1])
            .range([tO.maxOpacity, tO.minOpacity]);
        
            d3.range(tO.stripes + 1).map((r) => {
            g.append("path")
            .attr("d", (d) =>
                d3.line()(
                moves.map((e, i) => [e[0], e[1] + stripeSpacing * r + tO.margin])
                )
                )
            .attr("opacity", tO.pathOpacity)
            .attr("fill", "none")
            .attr("stroke-width", tO.pathStrokeWidth)
            .attr("stroke", tO.particleColor)
            .attr("stroke-linecap", "round")
            .attr("stroke-linejoin", "round");
        
            g.append("path")
            .attr("d", (d) =>
                d3.line()(
                moves.map((e, i) => [-e[0], e[1] + stripeSpacing * r + tO.margin])
                )
                )
            .attr("opacity", tO.pathOpacity)
            .attr("fill", "none")
            .attr("stroke-width", tO.pathStrokeWidth)
            .attr("stroke", tO.particleColor)
            .attr("stroke-linecap", "round")
            .attr("stroke-linejoin", "round");
            });
        
            animate();
            const intervalId = window.setInterval(animate, tO.spawnDelay);
            function animate(){
            d3.range(tO.stripes + 1).map((y) => {
                const circleR = g
                .append("circle")
                .attr("fill", tO.particleColor)
                .attr("cx", 0)
                .attr("cy", y * stripeSpacing + tO.margin)
                .attr(
                "transform",
                "translate(" + moves[0][0] + "," + moves[0][1] + ")"
                )
                .transition()
                .delay((d) => Math.random() * tO.phaseRandomness)
                .duration(tO.enterDuration)
                .attr("r", tO.maxRadius)
                .attr("opacity", tO.maxOpacity);
        
                const circleL = g
                .append("circle")
                .attr("fill", tO.particleColor)
                .attr("cx", 0)
                .attr("cy", y * stripeSpacing + tO.margin)
                .attr(
                "transform",
                "translate(" + moves[0][0] + "," + moves[0][1] + ")"
                )
                .transition()
                .delay((d) => Math.random() * tO.phaseRandomness)
                .duration(tO.enterDuration)
                .attr("r", tO.maxRadius)
                .attr("opacity", tO.maxOpacity);
        
                moves.slice(1).map((move, i) => {
                if (i != moves.length - 2) {
                    circleR
                    .transition()
                    .duration(tO.stepDuration)
                    .ease(d3.easeLinear)
                    .delay(i * tO.stepDuration)
                    .attr("transform", "translate(" + move[0] + "," + move[1] + ")")
                    .attr("r", sizeScale(i))
                    .attr("opacity", opacityScale(i));
        
                    circleL
                    .transition()
                    .duration(tO.stepDuration)
                    .ease(d3.easeLinear)
                    .delay(i * tO.stepDuration)
                    .attr("transform", "translate(" + -move[0] + "," + move[1] + ")")
                    .attr("r", sizeScale(i))
                    .attr("opacity", opacityScale(i));
                } else {
                    circleR
                    .transition()
                    .duration(tO.stepDuration)
                    .ease(d3.easeLinear)
                    .delay(i * tO.stepDuration)
                    .attr("transform", "translate(" + move[0] + "," + move[1] + ")")
                    .attr("r", sizeScale(i))
                    .attr("opacity", opacityScale(i))
                    .transition()
                    .duration(tO.stepDuration)
                    .attr("r", 0)
                    .remove();
        
                    circleL
                    .transition()
                    .duration(tO.stepDuration)
                    .ease(d3.easeLinear)
                    .delay(i * tO.stepDuration)
                    .attr("transform", "translate(" + -move[0] + "," + move[1] + ")")
                    .attr("r", sizeScale(i))
                    .attr("opacity", opacityScale(i))
                    .transition()
                    .duration(tO.stepDuration)
                    .attr("r", 0)
                    .remove();
                }
                });
            });
            }
        }
        
        if((window.location.href).split('/')[5] == "creation"){
        
            const rowCount = (width - cO.margin * 2) / cO.spacing;
        
            let phase = 0;
        
            animate();
            const intervalId = window.setInterval(animate, cO.dropDelay);
            function animate(){
            const drops = d3
            .range(rowCount)
            .map((c) =>
                d3.range(r(cO.minClusterCount, cO.maxClusterCount)).map((p) => {
                const xSpread =
                phase % 2 == 0
                ? cO.spacing / 2 +
                r(-cO.clusterRadius, cO.clusterRadius) +
                cO.margin
                : r(-cO.clusterRadius, cO.clusterRadius) + cO.margin;
        
                const ySpread = r(-cO.clusterRadius, cO.clusterRadius);
        
                return {
                    cluster: c,
                    particle: p,
                    startOpacity: cO.minOpacity,
                    endOpacity: cO.maxOpacity,
                    startRadius: cO.minRadius,
                    endRadius: cO.maxRadius,
                    source: [cO.spacing * c + xSpread, -20 + ySpread],
                    target: [
                    cO.spacing * c + xSpread * cO.clusterSpreadRate,
                    height + ySpread * cO.clusterSpreadRate + cO.clusterRadius + 200
                    ]
                };
                })
                )
            .flat();
        
            drops.forEach((d) => {
                svg
                .append("circle")
                .attr("cx", d.source[0])
                .attr("cy", d.source[1])
                .attr("r", d.startRadius)
                .attr("opacity", d.startOpacity)
                .attr("fill", cO.particleColor)
                .transition()
                .duration(cO.fallDuration)
                .ease(d3.easeCubicIn)
                .attr("cy", d.target[1])
                .attr("r", d.endRadius)
                .attr("opacity", d.endOpacity)
                .remove();
            });      
            phase++;
            }
        }
        
        if((window.location.href).split('/')[5] == "causation"){
            const g = svg.append("g");
        
            let stripeSpacing = width / (zO.lineCount / 2);
        
            let endPoints = d3.range(-width, width + width, stripeSpacing);
        
            let radius = width / zO.lineCount;
        
            function paramPoint(lat1, long1, lat2, long2, t) {
            return [lat1 + (lat2 - lat1) * t, long1 + (long2 - long1) * t];
            }
        
        
        
            animate();
            const intervalId = window.setInterval(animate, zO.spawnDelay);
            function animate(){
            endPoints.map((x, i) => {
                d3.range(zO.squaresPerLine)
                .map((e) => Math.random())
                .map((t) => {
                let mP = paramPoint(x - radius, height + radius, width + x, 0, t);
        
                g.append("rect")
                .attr("x", mP[0] - zO.squareSize / 2)
                .attr("y", mP[1] - radius - zO.squareSize / 2)
                .attr("width", zO.squareSize)
                .attr("height", zO.squareSize)
                .attr("fill", zO.particleColor)
                .attr("opacity", 0)
                .attr("transform", "rotate(0," + mP[0] + "," + mP[1] + ")")
                .transition()
                .duration(zO.enterDuration)
                .delay(Math.random() * zO.enterDuration)
                .attr("opacity", zO.maxOpacity)
                .transition()
                .duration(zO.stepDuration)
                .delay(Math.random() * zO.stepDuration)
                .attr("opacity", zO.minOpacity)
                .attrTween("transform", (d, i, a) => {
                    return d3.interpolateString(
                    "rotate(0," + mP[0] + "," + mP[1] + ")",
                    "rotate(90," + mP[0] + "," + mP[1] + ")"
                    );
                })
                .transition()
                .duration(zO.exitDuration)
                .delay(Math.random() * zO.exitDuration)
                .attr("opacity", 0)
                .remove();
                });
            });
            }
        
        }
    }
  })